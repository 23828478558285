<template>
  <CCard body-wrapper>
    <CForm @submit.prevent="createStore">
      <CInput
        label="Название"
        required
        :invalid-feedback="getScalarFieldError(errors, 'name')"
        :is-valid="isScalarFieldInvalid(errors, 'name') ? false : null"
        :value.sync="form.name"
      />
      <CInput
        label="Полный адрес"
        :invalid-feedback="getScalarFieldError(errors, 'fullAddress')"
        :is-valid="isScalarFieldInvalid(errors, 'fullAddress') ? false : null"
        :value.sync="form.address.fullAddress"
      />
      <CSelect
        label="Внешний склад"
        :options="externalStoresSelectOptions"
        :value.sync="form.externalStoreId"
      />
      <loading-button :loading="loading" @click="createStore" />
    </CForm>
  </CCard>
</template>

<script>
import Swal from 'sweetalert2';
import FormMixins from '@/mixins/form-mixins';
import WithExternalStores from '@/mixins/with-external-stores';
import LoadingButton from '@/components/loading-button';

export default {
  name: 'ShowStore',
  mixins: [FormMixins, WithExternalStores],
  components: {
    LoadingButton,
  },
  data() {
    return {
      form: {
        name: '',
        externalStoreId: null,
        address: {
          fullAddress: ''
        },
        ...this.draft
      },
      errors: {},
      loading: false
    }
  },
  computed: {
    draft() {
      return this.$store.getters.currentStoreDraft(this.draftUuid);
    },
    draftUuid() {
      console.log(this.$route, 'ROUTE!')
      return this.$route.query.uuid;
    },
    requestForm() {
      const res =  {...this.draft, ...this.form, addressAttributes: this.form.address};
      delete res.address;
      return res;
    }
  },
  mounted() {
    this.loadExternalStores();
  },
  methods: {
    async createStore() {
      try {
        this.loading = true;
        const {data} = await this.$axios.post(`/api/stores`, this.requestForm);
        await Swal.fire(
          'Успешно',
          'Склад добавлен',
          'success',
        );
        await this.$router.push({ name: 'admin.stores.show', params: { id: data.id } });
      } catch(e) {
        if (this.hasErrorsInResponse(e)) {
          this.errors = e.response.data.errors

          this.$nextTick(() => {
            this.showBaseErrors();
          })
        }
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
